
#app {
  display: flex;
  min-height: 100vh;
}
.router-layout{
  padding-left: 270px
}
.router-wrapper {
  transition: 0.4s;
  width: 100%;
  overflow: hidden;
}
