
/* sub menu with link */
.sub-menu-link {
  width: 100%;
  text-decoration: none;
  display: inline-block;
}
.footer {
    position:absolute;
    bottom: 10%;
    right: 15%;
    font-size: 10px;
    cursor: pointer;
}

// .logout {
//   color: #fff !important;
//   margin-top: 6%;
//     // position:absolute;
//     // bottom: 10%;
//     // right: 15%;
//     // font-size: 10px;
//     // cursor: pointer;
// }

// style manually overwriten
// .sidebar{
//   min-width: 15% !important;
// }
