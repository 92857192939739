// BUTTON
.el-button,
.el-button {
  border: none !important;

  &:focus,
  &:hover {
    color: unset !important;
    border: none;
  }
}

// TABLES BTNS
.custom-table-btn,
.custom-table-btn-dark,
.custom-table-btn-pale {
  background: $tab-view-color !important;
  padding: 13px 23px !important;
  color: $white !important;
  margin-left: 0 !important;
  margin-right: 10px !important;

  &:focus,
  &:hover {
    color: $white !important;
    border: none;
  }
}

.custom-table-btn-dark {
  background: $dark-green !important;
}

.custom-table-btn-pale {
  background: $pale-green !important;
}

// ACORDION
.custom-accordion {
  .el-collapse {
    border-top: 0 !important;
  }

  .el-collapse-item__arrow {
    font-size: 20px;
  }
}

// LABELS
.el-form-item__label {
  color: $secondary !important;
  font-family: $avenir-next-demi;
  font-weight: 600;
  letter-spacing: 0.1px;
}

.el-form-item__error {
  word-break: break-word;
}

// TEXT INPUT
.el-input.is-active .el-input__inner,
.el-input__inner:focus,
.el-textarea__inner:focus {
  border-color: $dark-green !important;
}

.docking-stations-wrapper,
.docking-station-logs-wrapper,
.customers-wrapper,
.roles-table-wrapper,
.bikes-wrapper,
.logs-wrapper,
.technical_dashboard-wrapper,
.users-wrapper,
.roles-wrapper,
.rides-wrapper,
.rent-tariffs-wrapper,
.site-wrapper,
.field-wrapper,
.station-commands-wrapper,
.tickets-wrapper {
  .actions {
    .el-input__inner {
      border-top: unset;
      border-left: unset;
      border-right: unset;
      border-radius: 0;
      border-color: $dark-green;
      font-family: $avenir-next-demi;
      height: unset;
      line-height: unset;
      padding: 10px;

      &:hover {
        color: $dark-green;
      }
    }

    .el-input__icon,
    input::placeholder {
      color: $dark-green;
      font-weight: 500;
      line-height: 23px;
    }

    .el-input--prefix .el-input__inner {
      padding: 10px 0;
      padding-left: 40px;
      font-size: 17px;
    }
  }
}

// DIALOG
.el-dialog {
  border-radius: 14px !important;
  padding: 15px;
}

.el-dialog__title {
  color: $dark-green !important;
  font-family: $avenir-next-bold;
  font-size: 22px !important;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 27px !important;
}

.el-dialog__header {
  padding: 20px !important;
  border-bottom: 1px solid $lighter-gray !important;
}

.el-dialog__headerbtn:focus .el-dialog__close,
.el-dialog__headerbtn:hover .el-dialog__close {
  color: $dark-green !important;
}

.el-dialog__headerbtn,
.el-message-box__headerbtn {
  .el-dialog__close,
  .el-message-box__close {
    background: $pop-up-close-bg;
    color: $white !important;
    border-radius: 4px;
    padding: 2px;
  }

  .el-icon-close:before {
    font-weight: bolder;
  }
}

.el-dialog__headerbtn:focus .el-dialog__close,
.el-dialog__headerbtn:hover .el-dialog__close {
  color: $white !important;
}

// ticket dialog body
.view-ticket-details-dialog {
  .el-dialog__body {
    width: 100% !important;
    padding: 0 !important;
  }

  .el-carousel__container {
    height: 200px;
    width: 388px;
    margin: 0 auto;
  }

  .el-carousel__item {
    border-radius: 16px;
    left: 50%;
    transform: translateX(-50%) !important;
  }

  .el-carousel__arrow {
    background: $carousel-arrow-color;
  }

  .el-carousel__arrow--right {
    right: -14px;
  }

  .el-carousel__arrow--left {
    left: -14px;
  }
}

// RADIO BUTTON
.el-radio__input.is-checked .el-radio__inner {
  background: $dark-green !important;
  border-color: $dark-green !important;
}

.el-radio__inner:hover {
  border-color: $dark-green !important;
}

.el-radio__input.is-checked + .el-radio__label {
  color: $dark-green !important;
}

// MODAL
.el-message-box__wrapper {
  .el-message-box {
    border-radius: 14px;
    padding-bottom: 30px;
    width: 567px;
  }

  .el-button--small {
    display: none;
  }

  .el-button--primary {
    @include red-btn();
    display: block;
    margin-top: 15px;
    font-size: 14px;
  }

  .el-message-box__btns {
    display: flex;
    justify-content: center;
  }

  .el-message-box__title {
    color: $dark-green;
    font-family: Avenir, sans-serif;
    font-size: 20px;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 27px;
    text-align: center;
  }

  .el-message-box__header {
    padding: 20px;
    border-bottom: 1px solid $lighter-gray;
  }

  .el-message-box__content {
    padding: 15px;
  }

  .el-message-box__status::before {
    display: none;
  }

  .el-message-box__status + .el-message-box__message {
    padding: 0;
    color: $secondary;
    font-family: Avenir, sans-serif;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 25px;
    text-align: center;
  }

  .el-message-box__headerbtn:focus .el-message-box__close,
  .el-message-box__headerbtn:hover .el-message-box__close {
    color: $dark-green;
  }
}

// DIALOGS TABLE STYLING
.bike-modal-table,
.view-transaction-details,
.transmitter-details-modal,
.rent-tariff-details-modal {
  .el-table {
    height: 500px;
  }

  .el-table th {
    background-color: $green-transparent !important;
    padding: 15px 0 !important;
  }

  .el-table th .cell {
    color: $dark-green;
    opacity: 0.8;
    letter-spacing: 0;
    line-height: 19px;
  }

  .el-table td .cell {
    color: $secondary;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
    width: initial;
    word-break: break-word;
  }
}

// VIEW RENT-TARIFF DETAILS DIALOG
.view-rent-tariff-details-dialog,
.view-bike-details-dialog,
.view-docking-station-dialog,
.view-transaction-details-dialog {
  .el-dialog__body {
    padding: 20px 0 !important;
  }

  .el-table td,
  .el-table th {
    padding: 20px 0;
  }
}

// DIALOGS TABLE
.el-dialog__body {
  .el-col-5 {
    color: $secondary;
  }

  .el-input__inner {
    color: $secondary;
    font-weight: 500;
    font-size: 16px;
  }
}

.el-tabs {
  .el-tabs__item {
    font-size: 15px !important;
    font-family: $avenir-next-demi;
  }

  .el-tabs__item.is-active,
  .el-tabs__item:hover {
    color: $tab-view-color !important;
  }

  .el-tabs__active-bar {
    background: $tab-view-color !important;
  }
}

.el-select-dropdown__item.selected {
  color: $primary !important;
}

.el-table {
  border-radius: 14px;

  .cell {
    overflow: unset !important;
  }

  td {
    .cell {
      text-overflow: initial;
      padding-left: 0 !important;
      font-size: 16px;
      color: $secondary;
    }
  }

  div {
    word-break: break-word;
  }
}

// ADD BULK FILENAME
.add-bulk {
  .doc-example {
    background: $primary;
    color: $white;
  }

  .title {
    .upload-wrapper {
      .file-name {
        color: $secondary;
      }
    }
  }
}

// ADD RENT TARIFF
.add-rent-tariff {
  .fee {
    h3 {
      color: $secondary;
    }
  }

  .fees-info {
    p {
      color: $secondary;
    }
  }
}

.view-bike-details {
  .el-textarea {
    textarea {
      color: $secondary;
      font-size: 16px;
      line-height: 22px;
    }
  }

  .el-table td,
  .el-table th {
    padding: 18px 0;
  }
}

// DATE PICKER OVERRIDE to CUSTOM COLORS
.el-date-table td.available:hover,
.el-picker-panel__shortcut:hover,
.el-picker-panel__icon-btn:hover {
  color: $primary !important;
}

.el-date-table td.today span {
  color: $primary !important;
}

.el-date-table td.end-date span,
.el-date-table td.start-date span {
  background-color: $primary !important;
}

// TRANSACTION DETAILS TABLE, REMOVE th PADDING LEFT
.view-transaction-details {
  .el-table th > .cell {
    padding-left: 0;
  }
}

// ALERT MESSAGE FONT
.el-message {
  .el-message__content {
    font-family: $avenir-next-regular;
  }
}

// custom-notify
.custom-notify {
  font-family: $avenir-next-regular;
}

// TOOLTIP CUSTOM
.el-tooltip__popper {
  width: 350px;
}

// custom pagination
.custom-pagination {
  margin: 10px 0;
  margin-left: auto;
  width: fit-content;
}

// DISABLED BTN
.disabled-btn {
  background: $disabled-btn !important;
}

// BIKE DETAILS - ADD USERS - TABS
.view-bike-details,
.add-user {
  .el-tabs__nav-scroll {
    display: flex;
    justify-content: center;
  }
}

// CUSTOM PAGINATION FOR PAGES
.el-pager .number {
  background-color: $white !important;
}

.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: $tab-view-color !important;
  color: $white !important;
}

// CUSTOM MAP DIALOG
.custom-map-dialog {
  .el-dialog {
    margin: 0 auto;
    margin-top: 5vh !important;
  }
}

// Navbar
.navbar {
  // display: inline-block;
  // height: 100%;
  // background-color: pink;
  .el-menu {
    // border-radius: 0 0 40px 0;
    // background-image: linear-gradient($gradient-start, $gradient-end);
    // background-image: linear-gradient(#f0f2f8,#f0f2f8);
    // max-height: 100%;

    li {
      margin: 5px 0;
    }

    .el-menu-item,
    .sub-menu-link {
      overflow: hidden;
    }

    span {
      display: inline-block;
    }

    &.scroll-el-menu {
      // height: 100%;
      // min-width: 350px;
      min-width: 250px;
      overflow-y: scroll;
    }
  }

  .el-menu-side-nav-wrapper {
    background-color: transparent;
    // display: flex;
    position: fixed !important;
    // min-height: 100vh;
    // height: 100%;
    // margin-left: 130px;
    width: 99%;
    height: 130px;
    transition: 0.3s;
    will-change: transform;
    transform: translateX(0px);
    z-index: 99;
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    // border-radius: 0% 15% 15% 0%;
    // border-top-right-radius: 50px 50px;
    // border-bottom-right-radius: 50px 50px;

    .nav_wrapper{
      width: 100%; 
      transition: 0.3s;
      will-change: transform;
      transform: translateX(0px);

      border-top-right-radius: 50px 50px;
      border-bottom-right-radius: 50px 50px;
      background-image: linear-gradient($gradient-start, $gradient-end);
      display: flex;
      // height: 100%;
      height: 70px;

      .logo-wrapper {
        // position: absolute; 
        // position: fixed !important;
        width: 128px;
        height: 128px;
        // height: 100%;
        // margin: auto;
        z-index: 100;
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
  
        border-bottom-right-radius: 50px 50px;
        padding-right: 1px;
        padding-bottom: 1px;
        background-color: $gradient-start;
      }

      .logo-words-wrapper {
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        width: 300px;
        height: 30px;
      }
      .items_wrapper{
        height: 70px;
        // margin-left: auto;
        // background-color: blue;
        // gap: 50px;
        display: flex;
        // justify-content: space-around;

        .el-menu-item{
          // width: 250px;
        }

        .field-filter{
          margin-left: 0;
          // margin-top: 15px;
          width: 98%;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;

        }
      }

      .profile_wrapper{
        margin-left: auto;
        // background-color: pink;
        height: 70px;
        display: flex;

        .logout {
          // background: red;
          color: $white !important;
          padding-top: 25px;
          padding-right: 25px;
          padding-left: 25px !important;
        }
      }

    }



    .img-wrapper {
      display: inline-block;
      text-align: center;
      width: 30px;
      align-items: center;
      justify-content: center;
      margin-right: 16px;

      img {
        opacity: 0.6;
      }
    }

    .el-submenu__title {
      &:hover {
        span {
          color: #fff;
        }

        .img-wrapper {
          img {
            opacity: 1;
          }
        }
      }

      .router-link-active {
        span {
          color: #fff;
        }

        .img-wrapper {
          img {
            opacity: 1;
          }
        }
      }
    }

    .el-submenu {
      background-color: transparent;

      .el-menu-item {
        margin: 0;
        padding-left: 66px !important;
      }

      &.is-active {
        .router-link-active {
          span,
          i {
            color: white;
          }

          img {
            opacity: 1;
          }
        }
      }

      .el-menu {
        border-radius: 0;
        background: transparent;
      }
    }

    .el-menu-item {
      background-color: transparent;

      &.is-active,
      &:hover,
      &:focus {
        background: transparent;

        span {
          color: $white !important;
        }

        img {
          opacity: 1;
        }
      }
    }

    .el-menu-item i,
    .el-submenu__title i {
      color: $lighter-gray;
    }

    .el-submenu__title:hover {
      background: transparent;
    }

    i {
      color: $lighter-gray;
    }

    span {
      font-weight: thin;
      font-size: 16px;
      color: $lighter-gray;
    }
  }
  .el-menu-wrapper::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
  }

  @media only screen and (max-width: 1200px) {
    .logo-words-wrapper{
        display: none;
    }
  }
}

// SIDEBAR
.sidebar {
  .logo-wrapper {
    // position: absolute; 
    // position: fixed !important;
    width: 80px;
    height: 80px;
    // margin: auto;
    // z-index: 99;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    // padding-left: 15px;
    // max-width: 75px;
    // max-height: 75px;
    // width: 75px;
    // height: 75px;
    border-radius: 15%;
    // object-fit: cover;

    // border-top-right-radius: 50px 50px;
    // border-bottom-right-radius: 50px 50px;
    padding-right: 1px;
    padding-bottom: 1px;
    background-color: $gradient-start;
  }
  .el-menu {
    border-radius: 0 0 40px 0;
    background-image: linear-gradient($gradient-start, $gradient-end);

    li {
      margin: 5px 0;
    }

    .el-menu-item,
    .sub-menu-link {
      overflow: hidden;
    }

    span {
      display: inline-block;
    }

    &.scroll-el-menu {
      height: 100%;
      // min-width: 350px;
      min-width: 250px;
      overflow-y: scroll;
    }
  }

  .el-menu-wrapper {
    position: fixed !important;
    min-height: 100vh;
    height: 100%;
    width: 100%;
    max-width: 270px;
    transition: 0.3s;
    will-change: transform;
    transform: translateX(0px);
    z-index: 100;
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */

    &.closed-menu {
      transform: translateX(-270px);
    }

    .img-wrapper {
      display: inline-block;
      text-align: center;
      width: 30px;
      align-items: center;
      justify-content: center;
      margin-right: 16px;

      img {
        opacity: 0.6;
      }
    }

    .el-submenu__title {
      &:hover {
        span {
          color: #fff;
        }

        .img-wrapper {
          img {
            opacity: 1;
          }
        }
      }

      .router-link-active {
        span {
          color: #fff;
        }

        .img-wrapper {
          img {
            opacity: 1;
          }
        }
      }
    }

    .el-submenu {
      background-color: transparent;

      .el-menu-item {
        margin: 0;
        padding-left: 66px !important;
      }

      &.is-active {
        .router-link-active {
          span,
          i {
            color: white;
          }

          img {
            opacity: 1;
          }
        }
      }

      .el-menu {
        border-radius: 0;
        background: transparent;
      }
    }

    .el-menu-item {
      background-color: transparent;

      &.is-active,
      &:hover,
      &:focus {
        background: transparent;

        span {
          color: $white !important;
        }

        img {
          opacity: 1;
        }
      }
    }

    .el-menu-item i,
    .el-submenu__title i {
      color: $lighter-gray;
    }

    .el-submenu__title:hover {
      background: transparent;
    }

    i {
      color: $lighter-gray;
    }

    span {
      font-weight: thin;
      font-size: 16px;
      color: $lighter-gray;
    }
  }

  .el-menu-wrapper::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
  }
}
