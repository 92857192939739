
@import "@variables";

.statistic-header-small {
  opacity: 0.5;
  color: #061058;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.77px;
  line-height: 22px;
}

.statistic-header-bold {
  color: $dark-green;
  font-size: 20px !important;
  font-weight: 600;
  letter-spacing: -0.63px;
  line-height: 27px;
  font-family: $avenir-next-bold;
}

.info-box-statistic {
  display: flex;
  align-items: center;
  width: fit-content;
  min-width: 300px;
  background: $white;
  border-radius: 20px;

  margin: 0 20px;
}

.full-width {
  width: 100% !important;
}

.fit-content {
  width: fit-content !important;
}
