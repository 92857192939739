
.grid-buttons {
  display: inline-grid;
  // grid-template-rows: 40px 40px 40px;
  grid-template-columns: 40px 40px 40px;
  grid-gap: 3px;

  img {
    margin: auto 0;
  }

  i.el-icon-refresh-left {
    margin: auto 0;
  }
}
