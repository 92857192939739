.docking-stations-list-view,
.docking-station-logs-view,
.customers-view,
.docking-stations-map,
.bikes-map,
.bikes-view,
.logs-view,
.technical-dashboard-view,
.station-commands-view,
.rent-tariffs-view,
.users-view,
.user-view,
.roles-view,
.add-user-view,
.edit-user-view,
.role-view,
.edit-role-view,
.add-role-view,
.transactions-view,
.customer-view,
.site-view,
.fields-view,
.client-details-view,
.sensor-view,
.tickets-view,
.settings-view,
.tickets-settings-view,
.inventory-view,
.rides-view,
.ticket-view {
  margin-top: 40px;
  padding-right: 30px;
  min-width: 99%;

  h3 {
    color: $dark-green;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 27px;
    font-family: $avenir-next-bold;
  }

  .el-table td .cell {
    font-size: 16px;
    color: $secondary;
    line-height: 22px;
    font-family: $avenir-next-regular;
    word-break: break-word;
  }

  // make checkboxes like in design
  .green-check {
    background: $green-btn !important;
    border-color: transparent;
  }

  .text-cut {
    text-decoration: line-through;
  }

  .el-checkbox__input.is-checked .el-checkbox__inner {
    background: $green-btn;
    border-color: transparent;
  }

  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background: $green-btn;
    border-color: transparent;
  }

  .el-checkbox__inner {
    width: 18px;
    height: 18px;
    border-radius: 4px;
  }

  .el-checkbox__inner::after {
    height: 8px;
    left: 5px;
    top: 1px;
    width: 4px;
    border: 2px solid $white;
    border-left: 0;
    border-top: 0;
  }

  .el-checkbox {
    color: $secondary;
  }

  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: $secondary;
  }

  .el-breadcrumb__inner a:hover, .el-breadcrumb__inner.is-link:hover {
    color: $primary;
  }

  .last-breadcrumb-bold {
    .is-link {
      font-weight: normal;
    }

    .el-breadcrumb__item:last-child .el-breadcrumb__inner {
      font-weight: bolder;
      color: $secondary;
    }
  }

  // collapse
  .el-collapse-item__wrap {
    border-bottom: none;
  }

  .el-collapse-item__header {
    border-bottom: none;
  }

  .customers-wrapper,
  .roles-table-wrapper,
  .users-wrapper,
  .roles-wrapper,
  .add-user-wrapper,
  .edit-user-wrapper,
  .view-role-wrapper,
  .view-user-wrapper,
  .edit-role-wrapper,
  .add-role-wrapper,
  .sensors-wrapper,
  .site-wrapper,
  .field-wrapper {
    border: 1px solid $lighter-gray;
    border-radius: 14px;
    background-color: $white;
    margin-top: 25px;
    padding-bottom: 25px;
    width: 98%;

    .disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    .actions {
      padding: 26px;

      .el-input {
        width: 227px;
      }
    }

    .table-header {
      opacity: 0.8;
      background-color: $lighter-gray;
      padding: 15px;

      .cell {
        opacity: 0.8;
        color: $dark-green;
        font-weight: 900;
        letter-spacing: 0;
        line-height: 19px;
        font-family: $avenir-next-bold;
        white-space: pre-line;
        word-break: break-word;
      }
    }

    // user view and role view permissions list
    .permissions-list {
      display: grid;
      grid-template-columns: repeat(5, 1fr);

      .check-icon {
        color: $primary;
      }

      .x-icon {
        color: $red;
      }

      p {
        margin: 0;
        font-size: 16px;
      }
    }
  }

  // STATISTICS WRAPPER
  @media only screen and (max-width: 1600px) {
    .statistics {
      width: fit-content;
      margin: 0 auto;
      flex-wrap: wrap;
      justify-content: unset;
    }
  }
}


// NAVBAR CLIENT
.overview-view{
  margin-top: 135px;
  // padding-right: 30px;
  min-width: 100%;
  width: 100%;
  height: 75%;
  max-height: 75%;

  .map-wrapper {
    // margin: auto;
    border: 1px solid $lighter-gray;
    border-radius: 15px;
    background-color: $white;
    // margin-top: 25px;
    padding-top: 25px;
    padding-bottom: 25px;
    width: 98%;
    height: 100%;
    // background-color: #e41e26;

    // .actions {
    //   padding: 10px;

    //   .el-input {
    //     width: 227px;
    //   }
    // }
    .client-map {
      width: 100% !important;
      height: 95% !important;
      // margin: 0 auto;
      // margin-bottom: 32px;
    }
  }

  // STATISTICS WRAPPER
  @media only screen and (max-width: 1600px) {
    .statistics {
      width: fit-content;
      margin: 0 auto;
      flex-wrap: wrap;
      justify-content: unset;
    }
  }
}

.details-view{
  display: flex;
  gap: 5px;
  margin-top: 135px;
  // padding-right: 30px;
  // min-width: 98%;
  width: 98%;
  height: 90%;
  // max-height: 80%;

  // h3 {
  //   color: $dark-green;
  //   font-weight: 600;
  //   letter-spacing: 0;
  //   line-height: 27px;
  //   font-family: $avenir-next-bold;
  // }

  .el-table td .cell {
    font-size: 16px;
    color: $secondary;
    line-height: 22px;
    font-family: $avenir-next-regular;
    word-break: break-word;
  }

  .details-list-wrapper {
    display: flex;
    flex-direction: column;
    border: 1px solid $lighter-gray;
    border-radius: 14px;
    background-color: $white;
    // margin-top: 25px;
    padding-bottom: 25px;
    width: 20%;
    height: 100%;

    .disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    .table-header {
      opacity: 0.8;
      background-color: $lighter-gray;
      padding: 15px;

      .cell {
        opacity: 0.8;
        color: $dark-green;
        font-weight: 900;
        letter-spacing: 0;
        line-height: 19px;
        font-family: $avenir-next-bold;
        white-space: pre-line;
        word-break: break-word;
      }
    }

    .actions {
      margin-top: 10px;
      margin-left: 10px;
      margin-bottom: 10px;
      order: 1;
      // margin-bottom: auto;
      // margin: auto;
      // padding: 20px;
      width: 100%;
      .el-input {
        // margin: auto;
        width: 100%;
      }
    }

    .details-list {
      // background-color: #e41e26;
      order: 2;
      height: 100%;
      // margin-bottom: auto;
      // order: 1;
      // flex: 80%;
      // height: 100%;
    }
    .pagination {
      order: 3;
      // background-color: gray;
      margin-top: auto;
      // margin-bottom: 0px;
      position: relative !important;
      // bottom: 0;
      // order: 2;
      // flex: 20%;
      // height: 100%;
    }
  }

  .details-chart-wrapper {
    border: 1px solid $lighter-gray;
    border-radius: 14px;
    background-color: $white;
    // margin-top: 25px;
    padding-bottom: 25px;
    width: 85%;
    height: 100%;


    // .disabled {
    //   opacity: 0.5;
    //   pointer-events: none;
    // }
    // .actions {
    //   padding: 26px;

    //   .el-input {
    //     width: 227px;
    //   }
    // }
  }

  // STATISTICS WRAPPER
  @media only screen and (max-width: 1600px) {
    .statistics {
      width: fit-content;
      margin: 0 auto;
      flex-wrap: wrap;
      justify-content: unset;
    }
  }
}

.chart-component {
  height: 100%;

  .chart-title {
    padding:15px;
    margin:auto;
  }
  .chart-wrapper {
    margin-left: 15px;
    display: grid;
  
    // grid-template-columns: auto auto;
    // grid-template-rows: auto auto auto;
    grid-template-columns: auto;
    grid-template-rows: auto;
    column-gap: 15px;
    width: 100%;
    // height: 90%;

    .chart{
      // background-color: #e41e26;
      // width: 100%;
      // height: 100%;
    }
  }
}



.rent-tariffs-view {
  .rent-tariff-top-page-wrapper {
    margin-top: 20px;
  }
}

.view-bike-maintenance {
  .el-dialog {
    margin-top: 8vh !important;
  }
}

.view-customer-tickets-details-dialog,
.view-ride-details-dialog,
.view-add-bike-dialog,
.view-assign-bike-dialog,
.view-add-new-bike-dialog,
.view-bike-maintenance,
.view-add-bulk-dialog {
  .customer-tickets-view,
  .customer-tickets-wrapper {
    margin: 0;
    padding: 0;
  }

  .customer-tickets-wrapper {
    border: 0;
  }

  .el-input__inner {
    color: $tundora;
    font-weight: 600;
    font-size: 14px;
  }
}

.transaction-tariff-ammount {
  color: $dark-green;
  font-size: 32px !important;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 30px;
  font-family: $avenir-next-demi;
}

// CUSTOM TRANSACTIONS STATISTICS BOX
.transactions-view {
  .info-box-statistic {
    padding-bottom: 14px;

    h4 {
      font-size: 16px !important;
      padding-top: 14px;
    }

    .statistic {
      padding: 0px 20px;
    }

    .info > span {
      font-size: 24px !important;
    }
  }
}

// filters wrapper
.filters {
  width: 98%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.user-date-filter,
.transactions-date-filter,
.transactions-cost-center-filter,
.rent-tariff-filter,
.date-filter,
.docking-stations-filter,
.tickets-filter,
.customers-filter,
.fields-filter
.sites-filter {
  display: flex;
  flex-direction: column;
  margin-left: 20px;

  .el-range-editor.el-input__inner {
    width: 340px;
  }

  .el-range-editor.el-input__inner,
  .el-select .el-input__inner {
    border-radius: 8px;
    border: none;
  }

  .el-date-editor .el-range-input {
    color: $secondary;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    font-family: $avenir-next-demi;
  }

  .filter-title {
    font-size: 16px;
    color: $dark-green;
    font-weight: 600;
    opacity: 0.5;
    line-height: 22px;
    margin-bottom: 6px;
    font-family: $avenir-next-demi;
  }
}

.customer-view {
  .prices-wrapper {
    margin-top: 32px;

    .price {
      width: 100%;
      border-radius: 12px;
      background-color: $white;
      padding: 13px 0;
      justify-content: center;
      align-items: center;

      &:nth-child(2) {
        margin: 0 20px;
      }

      .price-info {
        margin-left: 20px;

        .title {
          margin-bottom: 9px;
        }
      }
    }
  }

  .view-tickets-btn {
    background: $green-btn !important;
  }

  .see-contact-details-btn {
    background: $dark-green !important;
  }

  .extra-charge-btn {
    background: $green-btn !important;
  }

  .restrict-btn {
    background: $danger-btn !important;
  }

  .device-info-btn {
    background: $device-info-btn !important;
  }
}

// dialog view details
.view-contact-details,
.view-device-info-details,
.view-bike-details,
.view-ticket-details,
.view-transaction-details,
.view-ride-details,
.view-docking-station-details,
.view-rent-tariff-details,
.view-bike-details {
  .details-bold .el-col span {
    font-family: $avenir-next-demi;
    color: $secondary;
  }

  // el description
  .el-descriptions-item__label:not(.is-bordered-label) {
    font-family: $avenir-next-demi;
    color: $secondary;
  }

  .el-descriptions :not(.is-bordered) .el-descriptions-item__cell {
    color: $secondary;
  }

  //---------------
  .text-normal .el-col span {
    color: $secondary;
  }
}

// BIKES VIEW
.bikes-view {
  .tab-view {
    .el-tabs__nav {
      display: flex;
      width: 100%;
    }
  }
}

// SECONDARY INPUTS
.transactions-view,
.customers-view {
  .db-search {
    margin-left: 10px;
    width: 277px !important;
  }
}

// BUTTONS
.delete-btn {
  box-shadow: 0 10px 20px 0 rgba(228, 30, 38, 0.21);
  color: white !important;
  background: #e41e26 !important;
}
