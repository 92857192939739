@import "@variables";

.login-view,
.forgot-password-view,
.set-password-view,
.change-email-view {
  width: 100%;
  height: 100%;
  background-image: url("../assets/auth/background.jpg");
  // background-size: 1200px 800px;
  background-repeat: no-repeat;
  // background-position: center center;
  background-position: 100% 100%;
  background-size: cover;
  // width: 1200px;
  // height: 1200px;
  // opacity: 0.4;

  .header-img {
    width: 100%;
  }

  .background {
    height: 100vh;
    top: 0;
    right: 0;
    // background-image: linear-gradient($gradient-start, $gradient-end);
    display: flex;
    flex: 50%;
  }

  .form {
    // margin-bottom: auto;
    margin-bottom: 150px;
    display: flex;
    flex: 50%;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .background-wrapper {
      background-color:  hsla(0, 0%, 100%, 0.5);
      width: 600px;
      max-width: 600px;
      margin: 50px 50px;
      border-radius: 14px;
    }
  }

  h2 {
    color: $dark-green;
    letter-spacing: 0;
    line-height: 36px;
    margin-top: 30px;
  }

  h4 {
    color: white;
  }

  h2,
  h4 {
    font-family: $avenir-next-bold;
  }

  .dark-green-btn {
    margin-top: 37px !important;
  }

  .el-input {
    border-radius: 7px;
    background-color: #ffffff;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.05);
  }

  .el-button.is-disabled {
    background: $disabled-btn;

    &:hover {
      background: $disabled-btn;
    }
  }

  .background-wrapper {
    background: white;
    padding-bottom: 50px;
  }


}

@media (max-width: 600px) {
  // .login-view {
  //     // // margin: 0;
  //     // // background-color: #bb1818;
  //     // width: 100%;
  //     // height: 100%;
  //     // background-image: url("../assets/auth/background.jpg");
  //     // // background-size: 1200px 800px;
  //     // background-repeat: no-repeat;
  //     // background-position: center center;
  //     // // background-position: 100% 100%;
  //     // background-size: cover;
  //     // width: 1200px;
  //     // height: 1200px;
  // }
}
