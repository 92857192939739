
@import "@variables";

.mapWrapper {
  width: 100% !important;
  height: 340px !important;
  margin: 0 auto;
  margin-bottom: 32px;
}

.mapWrapperClient {
  width: 100% !important;
  height: 500px !important;
  margin: 0 auto;
  margin-bottom: 32px;
}

