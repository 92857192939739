
@media only screen and (max-width: 600px) {
  .responsive {
    display: flex;
    flex-direction: column !important;
    overflow: scroll;

    .el-col {
      width: 100%;
    }
  }
}

.el-form {
  // padding: 0 60px;

  .el-form-item {
    margin-bottom: 0;
  }
}
