// Colors
$primary: #739072;
$secondary: #191919;
// $body-background: #86A789;
// $body-background: #f0f2f8;
$body-background: #f3f5ee;

$white: #fff;
$gray: #adb5bd;
$red: #e41e26;
$tundora: #4e4e4e;

$light-gray: #abbbf8;
$lighter-gray: #D2E3C8;

$warning: #ff8d13;
$yellow: #e9b50e;

// $gradient-start: #739072;
// $gradient-end: #86A789;

// $gradient-start:  #75b500;
// $gradient-end: #75b500;

// $gradient-start:  #568500;
// $gradient-end: #568500;

$gradient-start: #3C9148;
$gradient-end: #3C9148;


$dark-green: #202d27;
// $dark-green: #061058;
$pale-green: #D2E3C8;
$disabled-btn: #b1b5c3;
$green-btn: #00984b;
$gray-btn: #c3c3c3;
// $green-btn: #3050ef;
$danger-btn: #fe3644;
$device-info-btn: #ffbd03;

$completed-green: #14d75b;
$canceled-red: #e41e26;
$pending-yellow: #ffd666;

$alizarin-crimson-transparent: #e41e2636;
$perano-transparent: #abbbf866;
$green-transparent: #cbd0dc99;

$tab-view-color: #739072;
$carousel-arrow-color: #264ae8;
$pop-up-close-bg: #cbd0dc;
// Fonts
$avenir-next-bold: "AvenirNextLTProBold";
$avenir-next-demi: "AvenirNextLTProDemi";
$avenir-next-regular: "AvenirNextLTProRegular";
// $font-size-base: 0.875rem;
